<template>
  <div class="atkm-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text watch-text">Watch <b>LIVE</b> on <b>Sundays @ 11 A.M.</b> and <b>Wednesdays @ 7:30 P.M.</b></p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <i @click="onLinkClick('https://www.facebook.com/erica.blair.94695')"
           class="fa-brands fa-facebook atkm-social-media-icon facebook"></i>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-icon-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text watch-text">Watch <b>ON-DEMAND</b></p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <i @click="onLinkClick('https://www.youtube.com/@BishopJohnBlair')"
           class="fa-brands fa-youtube atkm-social-media-icon youtube"></i>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-icon-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text watch-text">Listen <b>ON-DEMAND</b></p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <i @click="onLinkClick('https://open.spotify.com/show/4uJPOcxhhezZw3vIVUPLqY')"
           class="fa-brands fa-spotify atkm-social-media-icon spotify"></i>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <atkm-separator />
    <b-row class="atkm-credit-debit-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <a class="atkm-button-link"
           :href="paypalLink">
          Donate Online
        </a>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { getPaypalLink } from "@/utils/Url";
import { BRow, BCol } from "bootstrap-vue-next";
import AtkmSeparator from "@/components/AtkmSeparator";

export default {
  name: "AtkmInfo",
  computed: {
    paypalLink() {
      return getPaypalLink();
    }
  },
  methods: {
    onLinkClick(url) {
      window.open(url, "_blank");
    }
  },
  components: {
    AtkmSeparator,
    BRow,
    BCol,
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-watch-info.scss";
</style>